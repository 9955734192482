import { StripeCard } from "@services/plan-and-billing/plan-and-billing.service";
import { StripeCardToken } from "./stripe-card-token.meta";

export interface SubscriptionTier {
  active: boolean;
  billing_scheme: 'per_unit' | string;
  created: number;
  currency: 'usd' | string;
  custom_unit_amount: null;
  flex?: Omit<SubscriptionTier, 'flex'>;
  id: string;
  livemode: boolean;
  lookup_key: null;
  metadata: {
    txn_limit?: string;
    transactions?: string;
    overage_ID?: string;
    PRODUCT?: string;
  };
  nickname: string;
  object: 'price' | string;
  product: string;
  recurring: null | {
    aggregate_usage: string;
    interval: 'month' | 'year';
    interval_count: number;
    trial_period_days: null;
    usage_type: 'licensed' | 'metered';
  };
  tax_behavior: 'unspecified' | string;
  tiers_mode: null;
  transform_quantity: null;
  type: 'recurring';
  unit_amount: number;
  unit_amount_decimal: string;
}

export interface SubscriptionPlan {
  active: boolean;
  attributes: string[];
  created: number;
  default_price: string;
  description: string;
  id: string;
  images: string[];
  livemode: boolean;
  metadata: Record<string, string | boolean>;
  name: string;
  object: string;
  package_dimensions: null;
  prices?: SubscriptionTier[];
  shippable: null;
  statement_descriptor: string;
  tax_code: null;
  type: string;
  unit_label: null;
  updated: number;
  url: null;
}

export interface SubscriptionStartPayload {
  price: string;
  provider: BillingProvider;
  token?: string; // Only stripe payment requires a token
  type: Products;
  price_overage?: string;
  payment_method_id?: string;
}

export enum Products {
  Reporting = 'REPORTING',
  Register = 'REGISTER',
  Filing = 'FILING',
}

export type BillingProvider = 'stripe' | 'shopify';

export enum BillingProviderEnum {
  SHOPIFY = 'shopify',
  STRIPE = 'stripe'
}

export type SubscriptionIntervals = 'month' | 'quarter' | 'year'

interface LineData {
  id: string;
  object: string;
  amount: number;
  amount_excluding_tax: number;
  currency: string;
  description: string;
  discount_amounts: [];
  discountable: boolean;
  discounts: [];
  invoice_item?: string;
  livemode: boolean;
  metadata: {};
  period: {
    end: number;
    start: number;
  };
  plan: {
    id: string;
    object: string;
    active: true;
    aggregate_usage: string;
    amount: number;
    amount_decimal: string;
    billing_scheme: string;
    created: number;
    currency: string;
    interval: SubscriptionIntervals;
    interval_count: number;
    livemode: false;
    metadata: {
      overage_ID?: string;
      transactions?: string;
    };
    nickname: string;
    product: string;
    tiers: null;
    tiers_mode: null;
    transform_usage: null;
    trial_period_days: null;
    usage_type: string;
  };
  price: SubscriptionTier;
  proration: boolean;
  proration_details: {
    credited_items: null;
  };
  quantity: number;
  subscription: string;
  subscription_item: string;
  tax_amounts: [];
  tax_rates: [];
  type: string;
  unit_amount_excluding_tax: string;
}

export interface PaymentHistory {
  id: string;
  object: string;
  account_country: string;
  account_name: string;
  account_tax_ids: null;
  amount_due: number;
  amount_paid: number;
  amount_remaining: number;
  amount_shipping: number;
  application: null;
  application_fee_amount: null;
  attempt_count: number;
  attempted: boolean;
  auto_advance: boolean;
  automatic_tax: {
    enabled: boolean;
    status: null;
  };
  billing_reason: string;
  charge: string;
  collection_method: string;
  created: number;
  currency: string;
  custom_fields: null;
  customer: string;
  customer_address: {
    city: string;
    country: string;
    line1: string;
    line2: string;
    postal_code: string;
    state: string;
  };
  customer_email: null;
  customer_name: string;
  customer_phone: string;
  customer_shipping: null;
  customer_tax_exempt: string;
  customer_tax_ids: [];
  default_payment_method: null;
  default_source: null;
  default_tax_rates: [];
  description: null;
  discount: null;
  discounts: any[];
  due_date: null;
  effective_at: number;
  ending_balance: number;
  footer: null;
  from_invoice: null;
  hosted_invoice_url: string;
  invoice_pdf: string;
  last_finalization_error: null;
  latest_revision: null;
  lines: {
    object: string;
    data: LineData[];
    has_more: false;
    total_count: number;
    url: string;
  };
  livemode: boolean;
  metadata: {};
  next_payment_attempt: null;
  number: string;
  on_behalf_of: null;
  paid: true;
  paid_out_of_band: false;
  payment_intent: string;
  payment_settings: {
    default_mandate: null;
    payment_method_options: null;
    payment_method_types: null;
  };
  period_end: number;
  period_start: number;
  post_payment_credit_notes_amount: 0;
  pre_payment_credit_notes_amount: 0;
  quote: null;
  receipt_number: null;
  rendering_options: null;
  shipping_cost: null;
  shipping_details: null;
  starting_balance: 0;
  statement_descriptor: null;
  status: string;
  status_transitions: {
    finalized_at: number;
    marked_uncollectible_at: null;
    paid_at: number;
    voided_at: null;
  };
  subscription: string;
  subtotal: number;
  subtotal_excluding_tax: number;
  tax: null;
  tax_percent: null;
  test_clock: null;
  total: number;
  total_discount_amounts: [];
  total_excluding_tax: number;
  total_tax_amounts: [];
  transfer_data: null;
  webhooks_delivered_at: number;
}

export interface ShopifyPaymentHistory {
  id: number;
  name: string;
  price: string;
  billing_on: string;
  status: 'active' | 'cancelled';
  created_at: string;
  updated_at: string;
  activated_on: string;
  return_url: string;
  test: boolean;
  cancelled_on: string;
  trial_days: number;
  trial_ends_on: string;
  api_client_id: number;
  decorated_return_url: string;
  currency: 'USD';
}

export interface ConfirmSubScriptionPayload {
  type: Products;
  provider: BillingProvider;
  charge_id: string;
  merchant_state_registration_id?: number;
  merchant_state_filing_id?: number
}

export interface ActiveSubscription {
  business_profile_id: number;
  card_brand: string;
  card_exp_date: string;
  card_last4: string;
  created_at: string;
  external_customer_id: string;
  merchant_subscription_id: number;
  metadata: string;
  payment_method_id: string;
  price_id: string;
  price: SubscriptionTier;
  product_id: string;
  product: SubscriptionPlan;
  provider_subscription_id: string;
  provider: BillingProvider;
  subscription_status: string;
  type: Products;
  user_id: string;
  usage?: string | number;
}

export class PaymentMethodForm {
  public cardNumber = '';
  public cardMonth = '';
  public cardYear = '';
  public cardCVV = '';

  get stripeCard(): StripeCard {
    return {
      cvc: this.cardCVV,
      number: this.cardNumber,
      exp_month: this.cardMonth,
      exp_year: this.cardYear
    }
  }

  stripeCardToken: StripeCardToken;
}

export class SubscriptionForm {
  public selectedTier?: SubscriptionTier;
  public consent?: boolean;

  subscriptionPlans: SubscriptionPlan[] = [];
  subscriptionTiers: SubscriptionTier[] = [];

  private _selectedPlan: SubscriptionPlan;

  get selectedPlan(): SubscriptionPlan {
    return this._selectedPlan;
  }

  set selectedPlan(value: SubscriptionPlan) {
    this._selectedPlan = value;
    this.selectedTier = null;

    this.subscriptionTiers = value?.prices || [];
    this.subscriptionTiers.sort((a, b) => +a.metadata?.transactions - +b.metadata?.transactions);
  }

  _billingFrequency: 'month' | 'year' = 'month';

  get billingFrequency(): 'month' | 'year' {
    return this._billingFrequency;
  }

  set billingFrequency(value: 'month' | 'year') {
    this._billingFrequency = value;
    this.selectedTier = null;

    this.subscriptionTiers = this.selectedPlan?.prices
      .filter(t => t.recurring.interval === value);
  }
}
