<ng-container *ngIf="!isShopifyBusiness">
	<ng-container [ngSwitch]="cardDisplayStatus">
		<ng-container *ngSwitchCase="'hasCards'" [ngTemplateOutlet]="hasCardsRef"></ng-container>
		<ng-container *ngSwitchCase="'hasNoCard'" [ngTemplateOutlet]="hasNoCardRef"></ng-container>
		<ng-container *ngSwitchDefault>
			<br>
			<br>
			<ngx-skeleton-loader animation="pulse" appearance="" count="5"></ngx-skeleton-loader>
		</ng-container>
	</ng-container>
</ng-container>

<ng-container *ngIf="isShopifyBusiness">
	<div class="card-body">
		<div class="mb-3 mb-4">

			<div class="row">
				<div class="col-lg-6">
					<label [ngClass]="{
									'bg-primary-subtle border-primary': selectedShopifyBilling === 'external',
									'border-primary-subtle': selectedShopifyBilling !== 'external'
									}"
								 class="border rounded d-flex align-items-center p-2 mb-3" role="button">
						<span class="form-check form-check-custom form-check-solid form-check-sm align-items-start mx-2">
							<input (change)="selectShopifyBilling('external')" [checked]="selectedShopifyBilling === 'external'"
										 class="form-check-input" name="shopifyPaymentMethod" type="radio">
            </span>
						<div>
							<p class="mb-2">
								<img alt="shopify" class="img-fluid" src="assets/images/brand/shopify.png" width="20px" />
								Proceed on Shopify <span class="mdi mdi-open-in-new"></span>
							</p>
							<small class="mb-1 d-block">Pay
								<app-amount [amount]="paymentDetails.amount" />
							</small>
						</div>
					</label>
				</div>

				<div class="col-lg-6">
					<label [ngClass]="{
									'bg-primary-subtle border-primary': selectedShopifyBilling === 'credit',
									'bg-secondary-subtle': !shopifyCreditBalance,
									'border-primary-subtle': selectedShopifyBilling !== 'credit' && shopifyCreditBalance
									}"
								 class="border rounded d-flex align-items-center p-2 mb-3" role="button">
						<span class="form-check form-check-custom form-check-solid form-check-sm align-items-start mx-2">
							<input (change)="selectShopifyBilling('credit')" [checked]="selectedShopifyBilling === 'credit'"
										 [disabled]="!shopifyCreditBalance"
										 class="form-check-input" name="shopifyPaymentMethod" type="radio">
            </span>
						<div>
							<p class="mb-2">
								<img alt="shopify" class="img-fluid" src="assets/images/logo-icon.svg" width="20px" />
								AtomicTax Credit Balance:
								<span class="badge badge-pill bg-secondary-subtle text-primary fw-bolder">
									{{ shopifyCreditBalance | number }}
								</span>
							</p>
							@if (shopifyCreditBalance) {
								<small class="mb-1 d-block"> Use 1 credit </small>
							} @else {
								<a class="mb-1 d-block small" href="javascript:void(0)"
									 routerLink="/account-settings/plan/shopify">
									Top-up
								</a>
							}
						</div>
					</label>
				</div>
			</div>

			<button (click)="emitSelection()" [disabled]="isMakingPayment" class="btn btn-primary w-100 p-3 my-3"
							data-test-id="make-payment-button">
				Pay
				<i *ngIf="isMakingPayment" aria-hidden="true" class="mdi mdi-cisco-webex mdi-spin mx-1"></i>
			</button>
		</div>
	</div>
</ng-container>


<ng-template #hasCardsRef>
	<div class="card-body">
		<h3>Saved Cards</h3>

		<div class="row">
			<div *ngFor="let paymentMethod of paymentMethods" class="col-lg-6">
				<label *ngIf="paymentMethod.type === 'card'"
							 [ngClass]="{
									'bg-primary-subtle border-primary': selectedCard === paymentMethod,
									'border-primary-subtle': selectedCard !== paymentMethod
									}"
							 class="border rounded d-flex align-items-center p-2 mb-3" role="button">
						<span class="form-check form-check-custom form-check-solid form-check-sm align-items-start mx-2">
							<input (change)="selectCard(paymentMethod)" [checked]="selectedCard === paymentMethod"
										 class="form-check-input" name="paymentMethod" type="radio">
            </span>
					<span>
							<span class="fw-semibold text-gray-600">
								<img alt="card issuer" class="img-fluid" src="assets/images/brand/{{ paymentMethod.card?.brand.toLowerCase() }}.svg" />
							</span>
							<span class="fs-4 fw-bold mb-1 d-block">**** **** **** {{ paymentMethod.card?.last4 }}</span>
						</span>
				</label>
			</div>

			<div class="col-lg-6">
				<div class="border border-opacity-10 rounded d-flex justify-content-center p-4">
					<button (click)="toggleAddNewCardDisplay()" class="btn btn-light my-1" type="button">
						<i class="mdi mdi-plus"></i>
						New card
					</button>
				</div>
			</div>
		</div>

		<br />

		<app-new-card-form (cardTokenizeEnd)="handleCardTokenizedEvent($event)"
											 (cardTokenizeError)="handleCardTokenizeErrorEvent()"
											 (cardTokenizeStart)="handleCardTokenizeStartEvent()"
											 *ngIf="!selectedCard && displayAddNewCardForm" />
	</div>
</ng-template>

<ng-template #hasNoCardRef>
	<div class="card-body">
		<app-new-card-form (cardTokenizeEnd)="handleCardTokenizedEvent($event)"
											 (cardTokenizeError)="handleCardTokenizeErrorEvent()"
											 (cardTokenizeStart)="handleCardTokenizeStartEvent()" />
	</div>
</ng-template>
